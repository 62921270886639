import 'core-js/es/array';
import 'core-js/es/string';
import 'cross-fetch/polyfill';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import getAmplifyConfiguration from '../configurations/components/cognito/configuration';
import { Amplify, Auth } from 'aws-amplify';
import '@fortawesome/fontawesome-free/js/all.js';
import '@fortawesome/fontawesome-free/css/all.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far);
require('twig');
require('moment');
// I don't actually know where underscore is used. I want to kill it with fire, though
window['_'] = require('underscore');
require('jquery-validation');
require('phone.number.js');
require('jquery-ui');
require('jquery.ui.widget.js');
require('jquery-datetimepicker');
require('jquery.nicescroll');
require('blueimp-file-upload');
require('jquery-datetimepicker/jquery.datetimepicker.css');
require('Raleway.css');
// Bind an event to trigger before all ajax requests happen
$(document).bind('ajaxSend', function (event, jqXHR, settings) {
    // GET requests don't need to bother making CSRF requests for tokens
    if (settings.type.toLowerCase() === 'get') {
        return;
    }
    if ('data' in settings) {
        var params = new URLSearchParams(settings.data);
        if (params.has('csrfToken')) {
            return;
        }
    }
    getCSRF();
});
function getCSRF() {
    $.ajax({
        global: false,
        type: 'GET',
        dataType: 'json',
        async: false,
        url: route('csrfAjax'),
        success: function (data1) {
            $('.csrfToken').val(data1.csrfHash);
            $('.csrf_class').val(data1.csrfHash);
            $("meta[name='csrf-name']").attr('content', data1.csrfName);
            $("meta[name='csrf-hash']").attr('content', data1.csrfHash);
        },
    });
}
$(document).ready(function () {
    $('form.forget_pass, form.change_pass, form.validator-form').each(function (idx, form) {
        var validator = $(form).validate({
            errorPlacement: function (error, element) {
                var closest = $(element).closest('div.form-group-container');
                if (closest.length === 0) {
                    closest = $(element).closest('div.form-group');
                }
                closest.append(error);
            },
            highlight: function (element) {
                $(element).parent('.form-group').addClass('has-error').css('margin-bottom', '0');
            },
            unhighlight: function (element) {
                $(element).parent('.form-group').removeClass('has-error');
            },
            onfocusout: function (element, event) {
                // Validate normally UNLESS the focusout event was a click on the Close button.
                var isCloseBtnEvent = $('#closeBtn').is(event.relatedTarget);
                if (!isCloseBtnEvent) {
                    $(element).valid();
                }
            },
        });
        // Find any elements within the form object that have a data
        // attribute on them; then ensure that they're set to have
        // the validator run on the keyup event from javascript
        $(validator.currentForm)
            .find(':input')
            .filter(function (idx, elem) {
            return Object.keys($(elem).data()).length > 0;
        })
            .on('keyup', function (idx, elem) {
            validator.element($(this));
        });
    });
});
var auth = getAmplifyConfiguration();
Amplify.configure({
    Auth: auth,
});
window.amplifyAuth = Auth;
function signOut() {
    window.amplifyAuth.signOut();
}
function popup(serviceAvailabilityID, bySubmit, crossStateRow) {
    // condition to check the popup is called from submit request or not.
    if (bySubmit == 1) {
        $('#restricted_to_EEU').addClass('hidden');
        $('.bySubmitMsg').removeClass('hidden');
    }
    else if (bySubmit == 2) {
        $('.bySubmitMsg').addClass('hidden');
        $('#restricted_to_EEU').removeClass('hidden');
    }
    else {
        $('.bySubmitMsg').addClass('hidden');
        $('#restricted_to_EEU').addClass('hidden');
    }
    if (crossStateRow == null) {
        $('#contact_modal')
            .modal()
            .find('.modal-body')
            .load('/contact/popup_display/' + serviceAvailabilityID, function () { });
    }
    else {
        var popup_details = window.popupData[crossStateRow];
        window.loadModal(popup_details);
    }
}
window.signOut = signOut;
window.getCSRF = getCSRF;
window.popup = popup;
